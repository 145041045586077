<template>
  <div
    class="no-pad-wrap"
  >
    <div class="nav-tab-wrap nav-row">
      <div class="nav-bar">
        <router-link
          class="tab-button"
          :to="{ name: 'ProfileGeneral' }"
        >
          General
        </router-link>
        <router-link
          class="tab-button"
          :to="{ name: 'ProfilePlans' }"
        >
          Plans
        </router-link>
      </div>
      <div class="tab-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import useUserSingle from '@/composables/users/useUserSingle'

export default {
  setup() {
    const { user } = useUserSingle()

    return {
      user,
    }
  },
}
</script>

<style lang="scss" >

</style>
